import React from 'react'

const { BASE_PATH, PRODUCT_BLOCK_LINK1 } = process.env

import ArrowSvg from './ArrowSvg'

const BuisnessBanner = () => {
  return (
    <>
      <div className='buisness-banner'>
        <div className='buisness-banner-text'>
          <h1 className='buisness-banner-title-small'>HeadBox for Business</h1>
          <h1 className='buisness-banner-title-large'>
            A service-led meetings and events platform supercharged by AI software
          </h1>
          <h1 className='buisness-banner-subtitle'>Take your M&E programme to the next level</h1>
          <a href={BASE_PATH + PRODUCT_BLOCK_LINK1}>
            <button className='buisness-banner-button'>
              Find out more{' '}
              <div style={{ paddingLeft: '4px', display: 'inline' }}>
                <ArrowSvg color='white' />
              </div>
            </button>
          </a>
        </div>
        <img
          className='buisness-banner-image'
          aria-hidden={true}
          height='342px'
          width='542px'
          src={`/ilp-public/assets/images/proposals.png`}
        />
      </div>
      <style jsx>{`
        .buisness-banner {
          position: relative;
          width: 90%;
          max-width: 1216px;
          height: 395px;
          border-radius: 12px;
          background: radial-gradient(circle at bottom right, rgba(120, 20, 20, 1), rgba(10, 10, 10, 1) 80%);
          padding: 48px 32px 32px 32px;
          margin 40px;
          opacity: 1;
          overflow: hidden;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: 16px;
        }

        .buisness-banner-text {
          display: flex;
          flex-direction: column;
          width: 45%;
        }

        .buisness-banner-title-small {
          font-family: Montserrat;
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
          text-align: left;
          padding-bottom: 2px;
          color: rgba(175, 35, 28, 1);
          margin: 0;
        }

        .buisness-banner-title-large {
          font-family: Montserrat;
          font-size: 36px;
          font-weight: 800;
          line-height: 40px;
          text-align: left;
          color: white;
          margin: 0;
          padding-bottom: 16px;
        }

        .buisness-banner-subtitle {
          font-family: Montserrat;
          font-size: 18px;
          font-weight: 500;
          line-height: 28px;
          text-align: left;
          color: white;
          margin: 0;
          padding-bottom: 16px;
        }

        .buisness-banner-button {
          width: 160px;
          height: 32px;
          padding: 5px 10px;
          border-radius: 4px;
          background-color: rgba(175, 35, 28, 1);
          color: #fff;
          border: none;
          cursor: pointer;
          font-family: Montserrat;
          font-size: 16px;
          font-weight: 500;
        }

        .buisness-banner-image {
          position: absolute;
          bottom: -1px;
          right: -1px;
          max-width: 100%;
          max-height: 100%;
          border-radius: 4px;
        }

        @media (max-width: 1100px) {
          .buisness-banner {
            display: block;
            height: 625px;
            width: 91%;
            padding: 64px 0px 0px 64px;
          }

          .buisness-banner-title-small {
            font-size: 18px;
          }

          .buisness-banner-title-large {
            line-height: 40px;
            font-size: 36px;
          }

          .buisness-banner-subtitle {
            font-size: 18px;
          }

          .buisness-banner-text {
            width: 80%;
          }

          .buisness-banner-image {
            position: absolute;
            bottom: calc(-320px + 0.5vw);
            right: -1px;
            width: 93%;
            height: auto;
            overflow: hidden;
          }


        }

        @media (max-width: 900px) { 

        .buisness-banner-image {
            position: absolute;
            bottom: calc(-180px + 0.5vw);
            right: -1px;
            width: 93%;
            height: auto;
            overflow: hidden;
          }

        }

         @media (max-width: 640px) { 

         .buisness-banner-image {
            bottom: -30px;
          }


          .buisness-banner {
            display: block;
            height: 552px;
            padding: 32px 0px 0px 32px;
          }

         .buisness-banner-title-small {
            font-size: 16px;
          }

          .buisness-banner-title-large {
            line-height: 28px;
            font-size: 24px;
          }

          .buisness-banner-subtitle {
            font-size: 16px;
          }
         }
      `}</style>
    </>
  )
}

export default BuisnessBanner
