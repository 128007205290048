import React from 'react'

import GlobeSvg from './GlobeSvg'
import StarSvg from './StarSvg'

const Testimonials = ({ testimonials }) => {
  return (
    <>
      <div className='testimonials-container'>
        {testimonials.map((testimonial, index) => (
          <a
            target='_blank'
            rel='noopener noreferrer'
            href={testimonial.link}
            key={index}
            className='testimonial-card'>
            <section>
              <div className='stars'>
                {Array.from({ length: 5 }).map((_, index) => (
                  <StarSvg
                    key={index}
                    starFillColor={index < testimonial.numberStars ? '#D7918E' : 'white'}
                  />
                ))}
              </div>
              <div className='headline title-clip '>{testimonial.headline}</div>
              <div className='text-clip body-copy'>{testimonial.bodyCopy}</div>
            </section>

            <div className='footer'>
              <div>{testimonial.firstName}</div>
              <div className='locale'>
                <span className='globe'>
                  <GlobeSvg />
                </span>
                <div className='testimonial-name'> {testimonial.locale}</div>{' '}
              </div>
            </div>
          </a>
        ))}
      </div>

      <style jsx>{`
        .text-clip {
          display: -webkit-box;
          -webkit-line-clamp: 5; /* Number of lines you want to display */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
        }

        .title-clip {
          display: -webkit-box;
          -webkit-line-clamp: 3; /* Number of lines you want to display */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
        }
      
        .testimonials-container {
          padding: 60px 20px; /* Default padding for desktop */
          display: flex;
          gap: 20px;
          flex-wrap: wrap;
          justify-content: center;
          margin-bottom: 20px;
        }

        .testimonial-card {
          text-decoration: none; 
          display: flex;
          flex-direction: column;
          border: 1px solid #ddd;
          border-radius: 10px;
          padding: 20px;
          max-width: 288px;
          height: 314px;  
          background-color: #fff;
          justify-content: space-between;
        }

        .stars {
          display: flex;
          gap: 3px;
          margin-bottom: 10px;
        }

        .headline {
          font-weight: bold;
          color: #262626;
          font-size: 20px;
          weight: 800px
          line-height: 24px;
          margin-bottom: 10px;
        }

        .body-copy {
          color: #262626;
          font-size: 16px;
          margin-bottom: 15px;
          weight: 400px
          line-height: 24px;
          padding: 0;
          margin: 0;
        }

        .footer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
          font-size: 14px;
          weight: 600;
          color: #595a5a;
        }

        .locale {
          display: flex;
          align-items: center;
        }

        .globe {
          font-size: 18px;
          margin-right: 5px;
          padding-top: 5px;
        }

        @media (max-width: 660px) {
          .testimonials-container {
            flex-direction: column;
            width: 98%;
            gap: 15px;
          }

          .testimonial-card {
            max-width: 100%;
            height: auto;
          }
        }
      `}</style>
    </>
  )
}

export default Testimonials
